import { AnimatedContainer, Breadcrumbs, PageTitle } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";

import { PreventiveCreateForm } from "../components";

export function PreventiveCreate() {
  const { subsystemId, productId } = useRequiredParams();

  const defaultValues = productId
    ? { produtoId: +productId }
    : { subsistemaId: subsystemId ? +subsystemId : undefined };

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Adicionar manutenção</PageTitle>
        <PreventiveCreateForm
          redirectTo="../"
          useFormProps={{ defaultValues }}
        />
      </div>
    </AnimatedContainer>
  );
}
