import { DocumentDownload } from "iconsax-react";

import { DownloadFile, S3Image } from "@/shared/components";
import { useMutationDocumentDownload } from "@/shared/hooks/api";
import { ManutencaoArquivo } from "@/shared/services";

interface SliderComponentProps {
  file?: ManutencaoArquivo;
}
export function SliderContent({ file }: SliderComponentProps) {
  const documentDownload = useMutationDocumentDownload();

  return (
    <div className="flex h-[calc(100vh-8rem)] flex-1 items-center justify-center">
      {file?.seImagem ? (
        <S3Image
          file={file.arquivo}
          className="h-full rounded-xl object-contain"
          emptyIcon="default"
        />
      ) : (
        <DownloadFile
          className="flex h-full w-full rounded-xl bg-primary-light-1 text-primary"
          onPress={() => file && documentDownload.mutateAsync(file.arquivo)}
          isPending={documentDownload.isPending}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <DocumentDownload variant="Bold" />
            <span className="w-72 truncate text-center text-sm font-bold">
              {file?.arquivo}
            </span>
          </div>
        </DownloadFile>
      )}
    </div>
  );
}
