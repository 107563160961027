import { ArrowRight2 } from "iconsax-react";
import { MenuTrigger } from "react-aria-components";

import {
  Button,
  Menu,
  MenuItem,
  QueryHandler,
  Separator,
  Spinner,
} from "@/shared/components";
import { useMaintenancesStatus } from "@/shared/hooks/api";
import { capitalizeString } from "@/shared/utils";

interface ProgressMenuProps {
  maintenanceId: number;
}

export function ProgressMenu({ maintenanceId }: ProgressMenuProps) {
  const [maintenanceStatusQuery] = useMaintenancesStatus({
    text: "",
  });

  return (
    <QueryHandler {...maintenanceStatusQuery} renderLoading={() => <Spinner />}>
      {(data) => (
        <MenuTrigger>
          {/* TODO: Add button color by api */}
          <Button className="cursor-pointer rounded px-4 py-3" styleType="menu">
            {capitalizeString(
              data?.content.find((status) => status.id === maintenanceId)?.nome,
            ) || "Desconhecido"}
            <Separator orientation="vertical" className="ml-5 mr-2" />
            <ArrowRight2 size="12" color="white" />
          </Button>
          <Menu>
            {data?.content.map((action, index) => (
              <MenuItem isDisabled={action.id === maintenanceId} key={index}>
                {capitalizeString(action.nome)}
              </MenuItem>
            ))}
          </Menu>
        </MenuTrigger>
      )}
    </QueryHandler>
  );
}
