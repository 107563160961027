import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useIntersectionObserver } from "usehooks-ts";

import { useObjReducer } from "@/shared/hooks";
import {
  GetObjsManutencaoHistoricoGetData,
  apiProvider,
} from "@/shared/services";

type UseInfiniteHistoryFilters = Omit<
  GetObjsManutencaoHistoricoGetData,
  "page" | "elementsPerPage"
>;
export function useInfiniteHistory(initial?: UseInfiniteHistoryFilters) {
  const trigger = useIntersectionObserver({
    threshold: 0.5,
  });

  const [filters, updateFilters] = useObjReducer<UseInfiniteHistoryFilters>({
    ...initial,
  });

  const query = useInfiniteQuery({
    queryKey: ["historyInfinite", filters],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => {
      return apiProvider.services.ManutencaoHistoricosService.getObjsManutencaoHistoricoGet(
        {
          page: pageParam,
          elementsPerPage: PAGE_COUNT,
          ...filters,
        },
      );
    },
    getNextPageParam: (lastPage, pages) => {
      const apiPages = Math.ceil(lastPage.total / lastPage.elementPerPage);
      return apiPages > pages.length ? pages.length : undefined;
    },
  });

  useEffect(() => {
    if (trigger.isIntersecting && query.hasNextPage && !query.isFetching) {
      query.fetchNextPage();
    }
  }, [query, trigger.isIntersecting]);

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  const flatData = useMemo(
    () => query.data?.pages.flatMap((p) => p.content),
    [query.data?.pages],
  );

  return [
    { flatData, ...query },
    { filters, updateFilters, handleSearchChange },
    trigger,
  ] as const;
}
