import { Add, DocumentDownload } from "iconsax-react";
import { useState } from "react";

import {
  AnimatedContainer,
  Button,
  Card,
  QueryHandler,
  S3Image,
  Separator,
  Slider,
  SliderContent,
} from "@/shared/components";
import { useRequiredParams, useSwitch } from "@/shared/hooks";
import { useMaintenance } from "@/shared/hooks/api";

import {
  FileListError,
  FileListLoading,
  MediaFilesCreateModal,
} from "../components";

export function MediaFilesList() {
  //TODO: change param to callId
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId);

  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const { isOn, turnOn, setOpen } = useSwitch(false);

  return (
    <QueryHandler
      {...maintenanceQuery}
      renderLoading={FileListLoading}
      renderError={(error) => <FileListError error={error} />}
    >
      {(data) => (
        <AnimatedContainer className="mt-0">
          <Card className="shadow-header">
            <div className="flex items-center gap-4">
              <span className="text-base font-bold text-black-2">
                Arquivos e mídia
              </span>
              <Button
                styleType="default"
                className="flex gap-2 py-1 text-base font-bold"
                onPress={turnOn}
              >
                <Add size={24} /> Adicionar mídia
              </Button>
            </div>
            <Separator />
            <div className="grid grid-cols-4 gap-3">
              {data?.manutencaoArquivo?.map((file, index) =>
                file.seImagem ? (
                  <div
                    key={file.id}
                    onClick={() => {
                      setCarouselOpen(true);
                      setCarouselIndex(index);
                    }}
                  >
                    <S3Image
                      file={file.arquivo}
                      className="h-56 w-72 cursor-pointer rounded-md"
                      emptyIcon="default"
                    />
                  </div>
                ) : (
                  <div
                    key={file.id}
                    className="flex h-56 w-72 cursor-pointer justify-center rounded-md bg-primary-light-1 text-primary"
                    onClick={() => {
                      setCarouselOpen(true);
                      setCarouselIndex(index);
                    }}
                  >
                    <div className="flex flex-col items-center justify-center gap-2">
                      <DocumentDownload variant="Bold" />
                      <span className="w-52 truncate text-center text-sm font-bold">
                        {file.arquivo}
                      </span>
                    </div>
                  </div>
                ),
              )}
              <Slider
                total={data?.manutencaoArquivo?.length}
                isOpen={carouselOpen}
                onClose={() => setCarouselOpen(false)}
                selectedIndex={carouselIndex}
                setCarouselIndex={setCarouselIndex}
              >
                {data?.manutencaoArquivo?.map((file) => (
                  <SliderContent file={file} key={file.id} />
                ))}
              </Slider>

              {data && (
                <MediaFilesCreateModal
                  manutencaoId={data.id}
                  isOpen={isOn}
                  onOpenChange={setOpen}
                />
              )}
            </div>
          </Card>
        </AnimatedContainer>
      )}
    </QueryHandler>
  );
}
