import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumbs, PageTitle, TabPanel, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function ClientLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("client");

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do cliente",
      action: "get.client",
    },
    {
      id: `${baseUrl}unidade/`,
      href: `${baseUrl}unidade/`,
      label: "Lista de unidades",
      action: "list.unit",
    },
  ]);

  return (
    <div className="flex h-full flex-col overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <Breadcrumbs />
      <PageTitle className="py-4">Detalhes do cliente</PageTitle>
      <Tabs aria-label="Módulos do cliente" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
