import { ZodTypeAny } from "zod";

import {
  FormWizard,
  FormWizardProps,
} from "@/shared/components/form/form-wizard";

import { ProductGeneralDataForm } from "./product-general-data-form";
import { ProductTechnicalDataForm } from "./product-general-technical-data-form";

export interface ContactCreateFormWizardProps<TSchema extends ZodTypeAny>
  extends Pick<
    FormWizardProps<TSchema>,
    "useFormProps" | "formSchema" | "submitSchema"
  > {
  isEditing?: boolean;
}

export function ProductFormWizard<TSchema extends ZodTypeAny>({
  isEditing,
  ...props
}: ContactCreateFormWizardProps<TSchema>) {
  return (
    <FormWizard
      {...props}
      onSuccessRedirect="../"
      steps={[
        {
          heading: {
            head: "Dados gerais",
          },
          element: <ProductGeneralDataForm />,
        },
        {
          heading: {
            head: "Dados técnicos",
          },
          element: <ProductTechnicalDataForm isEditing={isEditing} />,
        },
      ]}
    />
  );
}
