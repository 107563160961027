import { useCallback } from "react";

import {
  FilterAndCreate,
  KanbanColumnLoading,
  KanbanContainer,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useSearchStore, useSwitch } from "@/shared/hooks";
import { useMaintenancesStatus, useProfile } from "@/shared/hooks/api";
import { MaintenanceTypeId } from "@/shared/utils";

import { MaintenanceCreateModal } from "../../create/components";
import { KanbanMaintenanceColumn } from "../components";

export function ListAssistanceKanban() {
  const { updateText } = useSearchStore();
  const profileQuery = useProfile();

  const [maintenanceStatusQuery] = useMaintenancesStatus({
    text: "",
  });

  const handleDebounceChange = useCallback(updateText, [updateText]);

  const { isOn, turnOn, setOpen } = useSwitch(false);

  return (
    <QueryHandler
      {...profileQuery}
      renderLoading={() => <Spinner className="mx-auto" />}
    >
      {() => (
        <div className="flex flex-col space-y-4 pt-4">
          <FilterAndCreate
            resourceName="chamado"
            onSearchChange={handleDebounceChange}
            createOnPress={turnOn}
            createAction="create.maintenance"
          />
          <div className="flex gap-3">
            <QueryHandler
              {...maintenanceStatusQuery}
              renderLoading={KanbanColumnLoading}
            >
              {(data) => (
                <KanbanContainer>
                  {data?.content
                    .slice()
                    .reverse()
                    .map((column) => (
                      <KanbanMaintenanceColumn
                        key={column.id}
                        status={{ id: column.id, name: column.nome }}
                        color={"#006555"}
                        maintenanceTypeId={MaintenanceTypeId.ASSISTANCE}
                      />
                    ))}
                </KanbanContainer>
              )}
            </QueryHandler>
          </div>
          {profileQuery.data?.construtoraId && (
            <MaintenanceCreateModal
              isOpen={isOn}
              onOpenChange={setOpen}
              tipoManutencaoId={MaintenanceTypeId.ASSISTANCE}
              construtoraId={profileQuery.data.construtoraId}
            />
          )}
        </div>
      )}
    </QueryHandler>
  );
}
