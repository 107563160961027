import { RouterProvider } from "react-aria-components";
import { Outlet, useHref, useNavigate } from "react-router-dom";

import { AuthorizationProvider } from "@/shared/contexts/authorization";

import PrivateHeader from "../components/private-header";

export function PrivateLayout() {
  const navigate = useNavigate();

  return (
    <RouterProvider
      navigate={(path, opts) =>
        navigate(new URL(path, window.location.href).pathname, opts)
      }
      useHref={useHref}
    >
      <AuthorizationProvider>
        <div className="flex h-screen w-screen flex-col bg-white-2">
          <PrivateHeader />
          <Outlet />
        </div>
      </AuthorizationProvider>
    </RouterProvider>
  );
}
