import { useParams } from "react-router-dom";

import {
  AnimatedContainer,
  FilterAndCreate,
  SearchInput,
} from "@/shared/components";
import { useUnits } from "@/shared/hooks/api";

import { UnitTable } from "../components";

export function UnitsList() {
  const { blockId, clientId } = useParams();

  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useUnits({
      text: "",
      blocoId: blockId ? +blockId : undefined,
      donoId: clientId ? +clientId : undefined,
    });

  return (
    <AnimatedContainer className="space-y-4">
      {blockId ? (
        <FilterAndCreate
          resourceName="unidade"
          onSearchChange={handleSearchChange}
          createAction="create.unit"
        />
      ) : (
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
      )}

      <UnitTable
        search={filters.text ?? undefined}
        rowsData={data?.content}
        isLoading={isLoading}
        pagination={{
          currentPage: filters.page ?? 1,
          pageCount: Math.ceil((data?.total ?? 0) / PAGE_COUNT),
          onPageChange: (page) => updateFilters({ page }),
        }}
      />
    </AnimatedContainer>
  );
}
