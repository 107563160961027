import {
  AnimatedContainer,
  InfiniteQueryHandler,
  Separator,
  StepDot,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteCosts } from "@/shared/hooks/api";

import CostCard from "../components/cost-card";

export function CostList() {
  const { serviceId } = useRequiredParams();
  const [{ flatData, isFetching, error }, , { ref }] = useInfiniteCosts({
    manutencaoId: +serviceId,
  });

  return (
    <AnimatedContainer className="mt-0 overflow-auto rounded-lg bg-white-0 px-6 pt-4">
      <span className="text-base font-bold text-black-2">Custos</span>
      <Separator />
      <div className="flex flex-col pb-6">
        <InfiniteQueryHandler
          isFetching={isFetching}
          error={error}
          isEmpty={!flatData?.length}
        >
          {flatData?.map((cost, index) => (
            <StepDot
              key={cost.id}
              ref={index === flatData.length - 1 ? ref : null}
              center
              isFirst={index === 0}
              isLast={index === flatData.length - 1}
            >
              <CostCard
                type={cost.tipo.nome}
                value={cost.valorCentavos}
                invoiceUrl={cost.notaFiscal}
              />
            </StepDot>
          ))}
        </InfiniteQueryHandler>
      </div>
    </AnimatedContainer>
  );
}
