import {
  AnimatedContainer,
  InfiniteQueryHandler,
  Separator,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteAppointments } from "@/shared/hooks/api";

import { AppointmentCard } from "../components";

export function AppointmentList() {
  const { serviceId } = useRequiredParams();
  const [{ flatData, isFetching, error }, , { ref }] = useInfiniteAppointments({
    manutencaoId: +serviceId,
  });

  return (
    <AnimatedContainer className="mt-0 overflow-auto rounded-lg bg-white-0 px-6 pt-4">
      <span className="text-base font-bold text-black-2">Agendamentos</span>
      <Separator />
      <div className="flex flex-col pb-6">
        <InfiniteQueryHandler
          isFetching={isFetching}
          error={error}
          isEmpty={!flatData?.length}
        >
          <div className="flex flex-col gap-4">
            {flatData?.map((appointment) => (
              <AppointmentCard
                key={appointment.id}
                appointment={appointment}
                ref={appointment.id === flatData.length - 1 ? ref : null}
              />
            ))}
          </div>
        </InfiniteQueryHandler>
      </div>
    </AnimatedContainer>
  );
}
