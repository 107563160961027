import {
  Checkbox as AriaCheckbox,
  CheckboxProps as AriaCheckboxProps,
} from "react-aria-components";

interface CheckboxProps extends Omit<AriaCheckboxProps, "children"> {
  children?: React.ReactNode;
}

export function Checkbox({ children, ...props }: CheckboxProps) {
  return (
    <AriaCheckbox {...props}>
      <div className="checkbox" aria-hidden="true">
        <svg viewBox="0 0 18 18">
          <polyline points="1 9 7 14 15 4" />
        </svg>
      </div>
      <span className="font-medium text-black-2">{children}</span>
    </AriaCheckbox>
  );
}
