import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import {
  Manutencao,
  UpdateObjManutencaoIdPutData,
  apiProvider,
} from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationMaintenanceUpdateConfig
  extends Omit<
    UseMutationOptions<Manutencao, Error, UpdateObjManutencaoIdPutData>,
    "mutationFn"
  > {}

export function useMutatioMaintenanceUpdate(
  options?: UseMutationMaintenanceUpdateConfig,
) {
  return useMutation({
    mutationFn:
      apiProvider.services.ManutencaosService.updateObjManutencaoIdPut,
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["maintenance", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar manutenção: ${e.message}`,
      }),
    ...options,
  });
}
