import { useEffect } from "react";
import { Link } from "react-aria-components";

import {
  Can,
  InfiniteQueryHandler,
  KanbanCard,
  KanbanColumn,
  KanbanColumnProps,
} from "@/shared/components";
import { useSearchStore } from "@/shared/hooks";
import { useInfiniteMaintenances } from "@/shared/hooks/api";

export function KanbanMaintenanceColumn({ ...props }: KanbanColumnProps) {
  const { searchText } = useSearchStore();

  const [
    { flatData, isFetching, error },
    { handleSearchChange },
    total,
    { ref },
  ] = useInfiniteMaintenances({
    text: "",
    tipoManutencaoId: props.maintenanceTypeId,
    statusManutencaoId: props.status.id,
  });

  useEffect(() => {
    handleSearchChange(searchText);
  }, [handleSearchChange, searchText]);

  return (
    <KanbanColumn {...props} columnLength={total}>
      <Can I="get.maintenance">
        {(allowed) => (
          <InfiniteQueryHandler
            isFetching={isFetching}
            error={error}
            isEmpty={!total}
            renderEmpty={() => (
              <div className="p-4 text-center text-sm font-semibold">
                Nenhum dado disponível
              </div>
            )}
          >
            {flatData?.map((maintenance, index) => (
              <Link
                key={maintenance.id}
                href={allowed ? `detalhes/${maintenance.id}/` : undefined}
              >
                <KanbanCard
                  color={"#006555"}
                  title={maintenance.unidade?.dono?.nome}
                  //TODO: Change to correct tag
                  tag="Lorem"
                  subtitle={maintenance.empreendimento?.nome}
                  footerTitle={"Responsável"}
                  footerValue={maintenance.contato?.nome}
                  ref={index === flatData.length - 1 ? ref : null}
                />
              </Link>
            ))}
          </InfiniteQueryHandler>
        )}
      </Can>
    </KanbanColumn>
  );
}
