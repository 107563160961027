import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import {
  Breadcrumbs,
  PageTitle,
  SubtitleHandler,
  Tabs,
} from "@/shared/components";
import {
  useAuthorizedTabs,
  useRequiredParams,
  useTabBaseUrl,
} from "@/shared/hooks";
import { useSystem } from "@/shared/hooks/api";

export function SystemLayout() {
  const { pathname } = useLocation();
  const { systemId } = useRequiredParams();

  const systemQuery = useSystem(systemId);

  const baseUrl = useTabBaseUrl("system");
  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do sistema",
      action: "get.system",
    },
    {
      id: `${baseUrl}subsistema/`,
      href: `${baseUrl}subsistema/`,
      label: "Subsistema",
      action: "list.subsystem",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <div className="flex flex-col">
          <PageTitle primary>Detalhes do Sistema</PageTitle>
          <SubtitleHandler label="Sistema" query={systemQuery} />
        </div>
      </div>
      <Tabs aria-label="Modulos do sistema" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
