export enum MaintenanceTypeId {
  PREVENTIVE = 1,
  ASSISTANCE = 2,
}

export enum MaintenanceStatus {
  REGISTERED = "registrado",
  PENDING = "pendente",
  REJECTED = "reprovado",
  APPROVED = "aprovado",
  WAITING = "aguardando",
  REQUEST_MORE_INFO = "solicitar mais informações",
  OPEN = "Em aberto",
  UNDER_REVIEW = "Em análise",
  IN_PROGRESS = "Em execução",
  REMOTE_INSPECTION_SCHEDULED = "Vistoria remota agendada",
  TECHNICAL_INSPECTION_SCHEDULED = "Vistoria técnica agendada",
  WAITING_FOR_REVIEW = "Aguardando avaliação",
  COMPLETED = "Concluído",
  UNFOUNDED = "Improcedente",
}
