import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumbs, PageTitle, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function UnitDetailLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("unit");

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados da unidade",
      action: "get.unit",
    },
    {
      id: `${baseUrl}arquivos/`,
      href: `${baseUrl}arquivos/`,
      label: "Arquivos",
      action: "list.document",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes da unidade</PageTitle>
      </div>
      <Tabs aria-label="Módulos da unidade" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
