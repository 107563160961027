import { Handle, RouteObject } from "react-router-dom";

import { DocumentList } from "@/shared/modules/document/containers";

import { editUnitAction } from "./actions";
import { UnitDetail, UnitDetailLayout, UnitEdit } from "./containers";

export const unitDetailRouter: RouteObject = {
  path: "detalhes/:unitId",
  handle: {
    crumb: "Detalhes da unidade",
    tabBaseUrl: "unit",
    action: "get.unit",
  } as Handle,
  children: [
    {
      element: <UnitDetailLayout />,
      children: [
        {
          index: true,
          element: <UnitDetail />,
        },

        {
          path: "arquivos",
          handle: {
            crumb: "Lista de arquivos",
            action: "list.document",
          } as Handle,
          element: <DocumentList />,
        },
      ],
    },
    {
      path: "editar",
      handle: {
        crumb: "Editar unidade",
        action: "update.unit",
      } as Handle,
      action: editUnitAction,
      element: <UnitEdit />,
    },
  ],
};
