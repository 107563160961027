import {
  Buildings,
  Edit,
  House,
  MessageQuestion,
  People,
  Setting,
} from "iconsax-react";

import { Am, Can } from "@/shared/components";

import DrawerElement from "./drawer-element";

export default function Drawer() {
  return (
    <div className="flex w-20 flex-col gap-4 border-r border-gray-1 bg-white-0 px-4 py-5 lg:w-60">
      <Am I="Construtora">
        <Can I="get.company">
          <DrawerElement
            to={`/app/construtora/`}
            tooltipText="Construtora"
            icon={(selected) => (
              <House variant={selected ? "Bold" : "Linear"} />
            )}
          >
            Construtora
          </DrawerElement>
        </Can>
      </Am>
      {(import.meta.env.DEV ||
        import.meta.env.VITE_SENTRY_ENV?.trim() === "homologation") && (
        <Am I="Construtora">
          {/* TODO: Add missing permission */}
          <DrawerElement
            to="/app/assistencia/"
            tooltipText="Assistência"
            icon={(selected) => (
              <Setting variant={selected ? "Bold" : "Linear"} />
            )}
          >
            Assistência
          </DrawerElement>
        </Am>
      )}
      <Am I="Construtora">
        <Can I="list.development">
          <DrawerElement
            to="/app/empreendimentos/"
            tooltipText="Empreendimentos"
            icon={(selected) => (
              <Buildings variant={selected ? "Bold" : "Linear"} />
            )}
          >
            Empreendimentos
          </DrawerElement>
        </Can>
      </Am>
      <Am I="SeuManual">
        <Can I="list.company">
          <DrawerElement
            to="/app/construtoras/"
            tooltipText="Construtoras"
            icon={(selected) => (
              <House variant={selected ? "Bold" : "Linear"} />
            )}
          >
            Construtoras
          </DrawerElement>
        </Can>
      </Am>
      <Can I="list.client">
        <DrawerElement
          to="/app/cliente/"
          tooltipText="Clientes"
          icon={(selected) => <People variant={selected ? "Bold" : "Linear"} />}
        >
          Clientes
        </DrawerElement>
      </Can>
      <Can I="list.faq">
        <DrawerElement
          to="/app/faq/"
          tooltipText="FAQ"
          icon={(selected) => (
            <MessageQuestion variant={selected ? "Bold" : "Linear"} />
          )}
        >
          FAQ
        </DrawerElement>
      </Can>
      <Am I="SeuManual">
        <DrawerElement
          to="/app/cadastros/"
          tooltipText="Cadastros"
          icon={(selected) => <Edit variant={selected ? "Bold" : "Linear"} />}
        >
          Cadastros
        </DrawerElement>
      </Am>
    </div>
  );
}
