import { TickCircle } from "iconsax-react";
import { useState } from "react";
import { ModalOverlayProps } from "react-aria-components";

import CheckBackground from "@/assets/check-background.svg?react";
import { Button, Dialog, Modal } from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { ApiError, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface MaintenanceApproveModalProps
  extends Omit<ModalOverlayProps, "children"> {
  maintenanceId: number;
}

export function MaintenanceApproveModal({
  maintenanceId,
  ...props
}: MaintenanceApproveModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const submitApproval = async (close: () => void) => {
    try {
      setIsLoading(true);
      await apiProvider.services.ManutencaosService.approveManutencaoAprovarIdPut(
        {
          id: maintenanceId,
        },
      );

      await queryClient.invalidateQueries({
        queryKey: ["maintenance", maintenanceId.toString()],
      });
    } catch (error) {
      const apiError = error as ApiError;

      toastQueue.add({
        type: "error",
        message: `Falha ao aprovar manutenção: ${apiError.message}`,
      });
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[40vw]"
        title="Confirme sua intenção de aprovar"
        renderButtons={({ close }) => (
          <>
            <Button
              className="px-7 py-3"
              showSpinner={isLoading}
              onPress={() => submitApproval(close)}
            >
              Confirmar
            </Button>
          </>
        )}
      >
        <div className="flex flex-col items-center justify-center gap-10">
          <div className="relative flex w-64 items-center justify-center">
            <TickCircle
              variant="Bold"
              className="absolute z-20 size-14 text-success duration-1000 animate-in fade-in spin-in"
            />
            <CheckBackground className="rotate-12 duration-1000 ease-in-out animate-in direction-alternate repeat-infinite" />
          </div>
          <div className="flex flex-col items-center gap-2">
            <span className="text-2xl font-semibold text-success">
              Aprovar chamado
            </span>
            <span className="text-center text-base font-medium text-gray-8">
              Você vai aprovar esse chamado, confirme essa ação abaixo
            </span>
          </div>
        </div>
      </Dialog>
    </Modal>
  );
}
