import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { EtapaCreate, apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

export async function developmentStepEditAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<EtapaCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...payload }) => {
      const developmentStepId = +params.developmentStepId!;
      await apiProvider.services.EtapasService.updateObjEtapaIdPut({
        id: developmentStepId,
        requestBody: payload,
      });
      await queryClient.invalidateQueries({
        queryKey: ["developmentStep"],
      });

      toastQueue.add(
        { type: "success", message: "Etapa atualizada com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
