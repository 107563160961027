import { useState } from "react";
import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { z } from "@/shared/utils";

import { AddPreventiveModal } from ".";
import { PreventiveCommon, PreventiveCommonSchema } from "../schemas";

const PreventiveContainerSchema = z.object({
  manutencoes: z.array(PreventiveCommonSchema).default([]),
});

type PreventiveContainer = z.infer<typeof PreventiveContainerSchema>;

interface FormPreventiveListProps<T extends PreventiveContainer> {
  className?: string;
  control: Control<T>;
}

export function FormPreventiveList<T extends PreventiveContainer>({
  control,
  ...props
}: FormPreventiveListProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<PreventiveContainer>;
  const [currentPreventive, setCurrentPreventive] =
    useState<PreventiveCommon>();

  return (
    <FormListInput
      {...props}
      control={_control}
      name="manutencoes"
      onAddPress={() => {
        setCurrentPreventive(undefined);
        turnOn();
      }}
      resourceOptions={{
        word: "manutenç",
        pluralRadical: "ões",
        singularRadical: "ão",
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
                onEdit={() => {
                  setCurrentPreventive(e);
                  turnOn();
                }}
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}
          {isOn && (
            <AddPreventiveModal
              isOpen={isOn}
              onOpenChange={setOpen}
              useFormProps={{ defaultValues: currentPreventive }}
              onAdd={(value) => {
                if (currentPreventive) {
                  const updatedPreventive = values.map((preventive) =>
                    preventive === currentPreventive ? value : preventive,
                  );
                  update(updatedPreventive);
                  return turnOff();
                }
                update([...values, value]);
                return turnOff();
              }}
            />
          )}
        </>
      )}
    </FormListInput>
  );
}
