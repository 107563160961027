import isMobilePhone from "validator/lib/isMobilePhone";
import isTaxID from "validator/lib/isTaxID";

import { falsyToNull, getDigits, z } from "@/shared/utils";

export const CreateUnitNewClientSchema = z.object({
  clientCreationType: z.literal("new-client"),
  unidade: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    blocoId: z.number().int(),
    dataEntrega: z.date().nullish(),
    tipoClientId: z.number().int(),
  }),
  responsavel: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    email: z.string().email(),
    telefone: z
      .string()
      .nullish()
      .refine((v) => !v || isMobilePhone(v, "pt-BR"), "Número inválido"),
    cpf: z
      .string()
      .nullish()
      .transform((v) => (v ? getDigits(v) : v))
      .refine((v) => !v || isTaxID(v, "pt-BR"), "CPF inválido"),
  }),
});

export const CreateUnitRegisteredClientSchema = z.object({
  clientCreationType: z.literal("registered-client"),
  unidade: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    blocoId: z.number().int(),
    dataEntrega: z.date().nullish(),
    donoId: falsyToNull(z.number().int().optional()),
    tipoClientId: z.number().int().nullish(),
  }),
});

export const CreateUnitNoClientSchema = z.object({
  clientCreationType: z.literal("no-client"),
  unidade: z.object({
    nome: z.string().trim().min(1, "Obrigatório"),
    blocoId: z.number().int(),
    dataEntrega: z.date().nullish(),
    tipoClientId: z.number().int().nullish(),
  }),
});

export const CreateUnitSchema = z.discriminatedUnion("clientCreationType", [
  CreateUnitNewClientSchema,
  CreateUnitRegisteredClientSchema,
  CreateUnitNoClientSchema,
]);

export type CreateUnit = z.infer<typeof CreateUnitSchema>;
export type CreateUnitNoClient = z.infer<typeof CreateUnitNoClientSchema>;
