import { Cell } from "react-aria-components";
import { useParams } from "react-router-dom";

import {
  Am,
  AnimatedContainer,
  Can,
  DetailsCell,
  FilterAndCreate,
  PaginationButtons,
  Table,
  TableRow,
} from "@/shared/components";
import { useIsCompanyRole, useSwitch } from "@/shared/hooks";
import { useMemorials } from "@/shared/hooks/api";
import { nullishStringMask } from "@/shared/utils/masks";

import { MemorialModalAssociate } from "../components";

export function MemorialList() {
  const { manualId } = useParams();
  const isCompanyRole = useIsCompanyRole();

  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useMemorials({
      manualId: manualId ? +manualId : 0,
      text: "",
      status: isCompanyRole ? true : undefined,
    });
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);

  return (
    <Am I="SeuManual">
      {(isSuperUser) => (
        <AnimatedContainer className="space-y-4">
          <FilterAndCreate
            resourceName="ambiente"
            onSearchChange={handleSearchChange}
            createOnPress={manualId && isSuperUser ? turnOn : undefined}
            createAction="create.memorial"
          />
          <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0">
            <div className="overflow-y-auto">
              <Can I="update.memorial">
                {(allowed) => (
                  <Table
                    aria-label="Lista ambientes"
                    headers={["Locais", "Categoria"]}
                    rowsData={data?.content}
                    isLoading={isLoading}
                    emptyProps={{
                      resource: "ambiente",
                      search: filters.text ?? undefined,
                    }}
                    removeDetailsColumn={!allowed}
                    renderRow={({ id, nome, categoria }) => (
                      <TableRow
                        key={id}
                        href={allowed ? `detalhes/${id}/` : undefined}
                      >
                        <Cell>{nome}</Cell>
                        <Cell>{nullishStringMask(categoria)}</Cell>
                        {allowed && <DetailsCell />}
                      </TableRow>
                    )}
                  />
                )}
              </Can>
            </div>
            <PaginationButtons
              currentPage={filters.page ?? 1}
              pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
              onPageChange={(page) => updateFilters({ page })}
            />
          </div>
          {manualId && (
            <MemorialModalAssociate
              manualId={+manualId}
              isOpen={isOn}
              onOpenChange={setOpen}
              onSuccess={turnOff}
            />
          )}
        </AnimatedContainer>
      )}
    </Am>
  );
}
