import { z } from "@/shared/utils";

export const MaintenanceCreateSchema = z.object({
  construtoraId: z.number().int(),
  empreendimentoId: z.number().int(),
  manualId: z.number().int(),
  contatoId: z.number().int(),
  unidadeId: z.number().int(),
  solicitacao: z.string().trim().nullish(),
  memorialId: z.number().int(),
  sistemaId: z.number().int(),
  subsistemaId: z.number().int(),
  produtoId: z.number().int().nullish(),
  garantiaId: z.number().int(),
  falhaId: z.number().int(),
  tipoManutencaoId: z.number(),
});

export type MaintenanceCreate = z.infer<typeof MaintenanceCreateSchema>;
