import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import { PageTitle, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function RecordLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("record");

  const tabs = useAuthorizedTabs([
    {
      id: `${baseUrl}contatos/`,
      href: `${baseUrl}contatos/`,
      label: "Contatos",
      action: "list.contact",
    },
    {
      id: `${baseUrl}sistema/`,
      href: `${baseUrl}sistema/`,
      label: "Sistemas",
      action: "list.system",
    },
    {
      id: `${baseUrl}ambiente/`,
      href: `${baseUrl}ambiente/`,
      label: "Ambientes",
      action: "list.memorial",
    },
    {
      id: `${baseUrl}post/`,
      href: `${baseUrl}post/`,
      label: "Posts",
      action: "list.post",
    },
    {
      id: `${baseUrl}produtos/`,
      href: `${baseUrl}produtos/`,
      label: "Produtos",
      action: "list.product",
    },
    {
      id: `${baseUrl}nbr/`,
      href: `${baseUrl}nbr/`,
      label: "NBR",
      action: "list.nbr",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <PageTitle primary>Cadastros</PageTitle>
      </div>
      <Tabs
        aria-label="Cadastros do sistema"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
