import { ArrowDown2 } from "iconsax-react";

import {
  AnimatedContainer,
  ApprovalHeader,
  Breadcrumbs,
  Can,
  Card,
  PageTitle,
  QueryHandler,
  ResourceProfileImage,
  ResponsibleListModal,
  Separator,
} from "@/shared/components";
import { useRequiredParams, useSwitch } from "@/shared/hooks";
import {
  useMaintenance,
  useMutatioMaintenanceUpdate,
} from "@/shared/hooks/api/maintenance";
import { MaintenanceStatus } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import { ProgressMenu } from "../../../components";
import {
  MaintenanceApproveModal,
  MaintenanceDetailError,
  MaintenanceDetailLoading,
  MaintenanceRejectModal,
} from "../components";

interface CallDetailDataProps extends React.PropsWithChildren {}

export function CallDetailData({ children }: CallDetailDataProps) {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId);
  const {
    isOn: isApprovalOn,
    turnOn: approvalTurnOn,
    setOpen: approvalSetOpen,
  } = useSwitch(false);
  const {
    isOn: isRejectOn,
    turnOn: rejectTurnOn,
    setOpen: rejectSetOpen,
  } = useSwitch(false);
  const {
    isOn: isResponsibleOn,
    turnOn: responsibleTurnOn,
    setOpen: responsibleSetOpen,
  } = useSwitch(false);

  const callUpdateMutation = useMutatioMaintenanceUpdate();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do chamado</PageTitle>
      </div>
      <div>
        <QueryHandler
          {...maintenanceQuery}
          renderLoading={MaintenanceDetailLoading}
          renderError={(error) => <MaintenanceDetailError error={error} />}
        >
          {(maintenance) => (
            <div className="space-y-4">
              {maintenance?.statusManutencao?.nome ===
                MaintenanceStatus.REGISTERED && (
                <>
                  <ApprovalHeader
                    title="Deseja aprovar esse chamado/assistência?"
                    turnApproveModalOn={approvalTurnOn}
                    turnRejectModalOn={rejectTurnOn}
                  />
                  <MaintenanceApproveModal
                    maintenanceId={+serviceId}
                    isOpen={isApprovalOn}
                    onOpenChange={approvalSetOpen}
                  />
                  <MaintenanceRejectModal
                    maintenanceId={+serviceId}
                    isOpen={isRejectOn}
                    onOpenChange={rejectSetOpen}
                  />
                </>
              )}
              <Card className="relative gap-4 rounded-none rounded-t-lg border-none px-6 pt-6">
                <div className="absolute left-0 top-0 h-[calc(100%+2rem)] w-2 rounded-l-lg bg-primary" />
                <div className="flex justify-between">
                  <div className="flex items-center gap-4">
                    <ResourceProfileImage size="small" file={undefined} />
                    <Can I="update.maintenance">
                      {(allowed) => (
                        <div
                          className="flex cursor-pointer items-center gap-3"
                          onClick={allowed ? responsibleTurnOn : undefined}
                        >
                          <div className="flex cursor-pointer flex-col justify-center">
                            <div className="flex items-center gap-2">
                              <p className="text-sm font-bold text-gray-10">
                                {nullishStringMask(maintenance?.contato?.nome)}
                              </p>
                            </div>
                            <div className="flex gap-6 text-xs text-primary">
                              <p className="flex gap-1">Responsável</p>
                            </div>
                            {maintenance && (
                              <ResponsibleListModal
                                isOpen={isResponsibleOn}
                                onOpenChange={responsibleSetOpen}
                                developmentId={maintenance.empreendimentoId}
                                onSelected={(responsible) => {
                                  callUpdateMutation.mutateAsync({
                                    id: +maintenance?.id,
                                    requestBody: {
                                      contatoId: responsible.id,
                                    },
                                  });
                                }}
                              />
                            )}
                          </div>
                          <ArrowDown2 size={16} className="text-gray-6" />
                        </div>
                      )}
                    </Can>
                  </div>
                  <div>
                    {/* TODO: Add pending maintenance status component */}
                    {maintenance?.statusManutencaoId && (
                      <ProgressMenu
                        maintenanceId={maintenance.statusManutencaoId}
                      />
                    )}
                  </div>
                </div>
                <Separator />
                <div className="flex justify-between">
                  <div className="flex gap-11 py-4 text-xs">
                    <div>
                      <p>Solicitante</p>
                      <p className="font-bold text-primary">
                        {nullishStringMask(maintenance?.unidade?.dono?.nome)}
                      </p>
                    </div>
                    <div>
                      <p>Empreendimento</p>
                      <p className="font-bold text-primary">
                        {nullishStringMask(maintenance?.empreendimento?.nome)}
                      </p>
                    </div>
                    <div>
                      <p>Unidade</p>
                      <p className="font-bold text-primary">
                        {maintenance?.unidade?.nome}
                      </p>
                    </div>
                    <div>
                      <p>Número de protocolo</p>
                      <p className="font-bold text-primary">
                        {maintenance?.id}
                      </p>
                    </div>
                  </div>
                  <div className="py-4">
                    {/* TODO: Componentize warranty tag */}
                    <span className="rounded bg-purple-10 p-[10px] text-sm font-bold text-purple">
                      Garantia com custos adicionais
                    </span>
                  </div>
                </div>

                <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
                  <div>
                    <p> Solicitação do cliente</p>
                    <p className="font-bold text-primary">
                      {maintenance?.resumo}
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </QueryHandler>
        {children}
      </div>
    </AnimatedContainer>
  );
}
