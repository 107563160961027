import { useParams } from "react-router-dom";

import { Breadcrumbs, PageTitle } from "@/shared/components";

import { MemorialCreateForm } from "../components";

export function MemorialCreate() {
  const { manualId } = useParams();

  return (
    <div className="flex h-full flex-col space-y-5 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Adicionar ambiente</PageTitle>
      </div>
      <MemorialCreateForm
        redirectTo="../"
        useFormProps={{
          defaultValues: {
            manualId: manualId ? +manualId : undefined,
          },
        }}
      />
    </div>
  );
}
