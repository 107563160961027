import { Building, Video } from "iconsax-react";
import { Link as LinkIcon } from "iconsax-react";
import { forwardRef } from "react";
import { Link } from "react-aria-components";

import { ResourceProfileImage, Separator } from "@/shared/components";
import { Vistoria } from "@/shared/services";
import {
  dateTimeMask,
  nullishStringMask,
  phoneMask,
} from "@/shared/utils/masks";

interface AppointmentCardProps {
  appointment: Vistoria;
}

export const AppointmentCard = forwardRef<HTMLDivElement, AppointmentCardProps>(
  ({ appointment }, ref) => {
    return (
      <div
        className="flex flex-col rounded-lg border border-white-3 px-6 py-5"
        ref={ref}
      >
        <span className="text-2xl font-semibold text-gray-10">
          Protocolo - {appointment.id}
        </span>
        <Separator className="my-5 border-gray-1" />
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-4 text-base font-semibold text-gray-10">
            <span className="text-base font-medium text-info">
              {/* //TODO - Refactor availability */}
              {appointment.manutencao?.disponibilidades &&
                dateTimeMask(
                  appointment.manutencao.disponibilidades[0]?.criadoEm,
                )}
            </span>

            <span>|</span>
            {appointment.seRemoto ? (
              <div className="flex items-center gap-2">
                <Video variant="Bold" size={20} />
                <span>Online</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Building variant="Bold" size={20} />
                <span>Presencial</span>
              </div>
            )}
          </div>
          <span className="text-sm font-normal text-gray-10">
            {appointment.observacoes}
          </span>
          {appointment.responsavel && (
            <div className="flex flex-col gap-3">
              <span className="text-sm font-semibold text-primary">
                Engenheiro responsável
              </span>
              <div className="flex gap-4 rounded border border-white-2 p-3">
                <ResourceProfileImage
                  file={appointment?.responsavel.logo}
                  className="size-12 rounded"
                />
                <div className="flex flex-col gap-2">
                  <span className="text-base font-semibold text-gray-12">
                    {appointment.responsavel.nome}
                  </span>
                  <div className="flex gap-4">
                    <span className="text-xs font-medium text-gray-7">
                      E-mail: {""}
                      <span className="font-light">
                        {nullishStringMask(appointment.responsavel.email)}
                      </span>
                    </span>
                    <span className="text-xs font-medium text-gray-7">
                      Telefone: {""}
                      <span className="font-light">
                        {nullishStringMask(
                          phoneMask(
                            appointment.responsavel.telefone ?? undefined,
                          ),
                        )}
                      </span>
                    </span>
                    <span className="flex gap-1 text-xs font-medium text-gray-7">
                      Site: {""}
                      <Link
                        className="flex items-center gap-1 font-bold text-info"
                        href={appointment.responsavel.site ?? undefined}
                        target="_blank"
                      >
                        {appointment.responsavel.site && (
                          <LinkIcon size="1rem" />
                        )}
                        {nullishStringMask(appointment.responsavel.site)}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);
