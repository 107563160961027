import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";

export function useMutationNbrDelete() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: apiProvider.services.NbrsService.deleteObjNbrIdDelete,
    onSuccess: async () => {
      navigate(-1);

      toastQueue.add(
        {
          type: "success",
          message: "NBR deletada com sucesso",
        },
        {
          timeout: 5000,
        },
      );
    },
    onError: (e) =>
      toastQueue.add(
        {
          type: "error",
          message: `Falha ao deletar NBR: ${e.message}`,
        },
        {
          timeout: 5000,
        },
      ),
  });
}
