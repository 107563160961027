import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumbs, PageTitle, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function ManualDetailLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("manual");
  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do manual",
      action: "get.manual",
    },
    {
      id: `${baseUrl}sistema/`,
      href: `${baseUrl}sistema/`,
      label: "Sistemas",
      action: "list.system",
    },
    {
      id: `${baseUrl}ambiente/`,
      href: `${baseUrl}ambiente/`,
      label: "Ambientes",
      action: "list.memorial",
    },
    {
      id: `${baseUrl}post/`,
      href: `${baseUrl}post/`,
      label: "Posts",
      action: "list.post",
    },
    {
      id: `${baseUrl}arquivos/`,
      href: `${baseUrl}arquivos/`,
      label: "Arquivos",
      action: "list.document",
    },
    {
      id: `${baseUrl}topico/`,
      href: `${baseUrl}topico/`,
      label: "Tópicos",
      action: "list.topic",
      role: "SeuManual",
    },
    {
      id: `${baseUrl}link/`,
      href: `${baseUrl}link/`,
      label: "Link",
      action: "get.manual",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do manual</PageTitle>
      </div>
      <Tabs aria-label="Módulos do manual" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
