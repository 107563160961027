import { Control } from "react-hook-form";

import { FormListInput, ListInputElement } from "@/shared/components";
import { useSwitch } from "@/shared/hooks";
import { UseInfiniteMemorialsFilters } from "@/shared/hooks/api";
import { z } from "@/shared/utils";

import { MemorialListModal } from ".";
import { MemorialCreateSchema } from "../schemas";

const MemorialContainerSchema = z.object({
  memoriais: z.array(MemorialCreateSchema).default([]),
});

type MemorialContainer = z.infer<typeof MemorialContainerSchema>;

interface FormWarrantyListProps<T extends MemorialContainer> {
  className?: string;
  control: Control<T>;
  filters?: UseInfiniteMemorialsFilters;
}

export function FormMemorialList<T extends MemorialContainer>({
  control,
  ...props
}: FormWarrantyListProps<T>) {
  const { isOn, turnOn, turnOff, setOpen } = useSwitch(false);
  const _control = control as Control<MemorialContainer>;

  return (
    <FormListInput
      {...props}
      control={_control}
      name="memoriais"
      onAddPress={turnOn}
      resourceOptions={{
        word: "ambiente",
        pluralRadical: "s",
        isMasculine: true,
      }}
    >
      {(values, update) => (
        <>
          {values.length !== 0 ? (
            values.map((e, i) => (
              <ListInputElement
                key={e.nome + i}
                onDelete={() =>
                  update(values.filter((_, index) => index !== i))
                }
              >
                {e.nome}
              </ListInputElement>
            ))
          ) : (
            <p className="p-4 text-sm italic text-gray-8">Vazio</p>
          )}

          <MemorialListModal
            filters={props.filters}
            isOpen={isOn}
            onOpenChange={setOpen}
            selectedMemorials={values}
            onAdd={(value) => {
              update([...values, value]);
              return turnOff();
            }}
          />
        </>
      )}
    </FormListInput>
  );
}
