import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import { AnimatedContainer, PageTitle, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function AssistanceLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("assistance");

  const tabs = useAuthorizedTabs([
    {
      id: `${baseUrl}kanban/`,
      href: `${baseUrl}kanban/`,
      label: "Kanban",
      action: "list.maintenance",
    },
    {
      id: `${baseUrl}lista/`,
      href: `${baseUrl}lista/`,
      label: "Lista",
      action: "list.maintenance",
    },
    {
      id: `${baseUrl}agendamentos/`,
      href: `${baseUrl}agendamentos/`,
      label: "Agendamentos",
      /* TODO: Add pending action */
    },
    {
      id: `${baseUrl}duvidas/`,
      href: `${baseUrl}duvidas/`,
      label: "Dúvidas",
      /* TODO: Add pending action */
    },
    {
      id: `${baseUrl}configuracoes/`,
      href: `${baseUrl}configuracoes/`,
      label: "Configurações",
      /* TODO: Add pending action */
    },
  ]);

  return (
    <AnimatedContainer className="mt-0 flex h-full w-[calc(100vw-240px)] flex-col space-y-6 overflow-y-auto p-8">
      <div className="space-y-4">
        <PageTitle primary>Assistência técnica</PageTitle>
      </div>
      <Tabs
        aria-label="Assistência técnica do sistema"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </AnimatedContainer>
  );
}
