import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { useForm } from "react-hook-form";

import { Button, Dialog, FormRichTextInput, Modal } from "@/shared/components";
import { toastQueue } from "@/shared/components/toast";
import { ApiError, apiProvider } from "@/shared/services";
import { queryClient, z } from "@/shared/utils";

const RejectSchema = z.object({
  reprovacao_justificativa: z.string(),
});
type Reject = z.infer<typeof RejectSchema>;

export interface MaintenanceRejectModalProps
  extends Omit<ModalOverlayProps, "children"> {
  maintenanceId: number;
}

export function MaintenanceRejectModal({
  maintenanceId,
  ...props
}: MaintenanceRejectModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const submitRejection = async (values: Reject, close: () => void) => {
    try {
      setIsLoading(true);
      await apiProvider.services.ManutencaosService.refuseManutencaoRecusarIdPut(
        {
          id: maintenanceId,
          requestBody: {
            reprovacao_justificativa: values.reprovacao_justificativa,
          },
        },
      );

      await queryClient.invalidateQueries({
        queryKey: ["maintenance", maintenanceId.toString()],
      });
    } catch (error) {
      const apiError = error as ApiError;

      toastQueue.add({
        type: "error",
        message: `Falha ao rejeitar manutenção: ${apiError.message}`,
      });
    } finally {
      setIsLoading(false);
      close();
    }
  };

  const { formState, control, handleSubmit, reset } = useForm<Reject>({
    resolver: zodResolver(RejectSchema),
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Justificativa"
        renderButtons={({ close }) => (
          <>
            <Button
              className="px-7 py-3"
              showSpinner={isLoading}
              onPress={() =>
                handleSubmit((values) => submitRejection(values, close))()
              }
            >
              Confirmar
            </Button>
          </>
        )}
      >
        <form className="grid grid-cols-2 gap-x-4 gap-y-6 p-1">
          <FormRichTextInput
            className="col-span-2"
            control={control}
            label="Descrição"
            name="reprovacao_justificativa"
          />
        </form>
      </Dialog>
    </Modal>
  );
}
