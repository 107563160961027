import {
  Modal as AriaModal,
  ModalOverlay,
  ModalOverlayProps,
} from "react-aria-components";
import { VariantProps, tv } from "tailwind-variants";

const overlayStyles = tv({
  base: "fixed inset-0 z-10 flex h-screen items-center justify-center p-4 backdrop-blur",
  defaultVariants: {
    styleType: "default",
  },
  variants: {
    isEntering: {
      true: "duration-300 ease-out animate-in fade-in",
    },
    isExiting: {
      true: "duration-200 ease-in animate-out fade-out",
    },
    styleType: {
      default: "bg-black-0/25 ",
      fullscreen: "bg-black-2/95",
    },
  },
});

const modalStyles = tv({
  base: "max-h-full overflow-hidden rounded-2xl bg-white-0 shadow-xl",
  variants: {
    isEntering: {
      true: "duration-300 ease-out animate-in zoom-in-95",
    },
    isExiting: {
      true: "duration-200 ease-in animate-out zoom-out-95",
    },
  },
});

interface CustomModalOverlayProps
  extends Omit<ModalOverlayProps, "className">,
    VariantProps<typeof overlayStyles> {}

export function Modal(props: CustomModalOverlayProps) {
  return (
    <ModalOverlay className={overlayStyles(props)} {...props}>
      <AriaModal className={modalStyles} {...props} />
    </ModalOverlay>
  );
}
