import { Handle, Navigate, RouteObject } from "react-router-dom";

import { AssistanceLayout } from "./containers";
import { ListAssistanceKanban } from "./modules/assistance-kanban/containers";
import { ListAssistances } from "./modules/services/containers";
import { serviceDetailRouter } from "./modules/services/modules/detail/router";

export const assistanceRouter: RouteObject = {
  path: "assistencia",
  handle: {
    tabBaseUrl: "assistance",
  } as Handle,
  children: [
    { index: true, element: <Navigate to="kanban/" /> },
    {
      element: <AssistanceLayout />,
      children: [
        {
          path: "kanban",
          handle: {
            crumb: "Kanban",
            action: "list.maintenance",
          } as Handle,
          element: <ListAssistanceKanban />,
        },
        {
          path: "lista",
          handle: {
            crumb: "Lista",
            action: "list.maintenance",
          } as Handle,
          element: <ListAssistances />,
        },
        {
          path: "agendamentos",
          handle: {
            crumb: "Agendamentos",
            /* TODO: Add pending action */
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "duvidas",
          handle: {
            crumb: "Dúvidas",
            /* TODO: Add pending action */
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "configuracoes",
          handle: {
            crumb: "Configurações",
            /* TODO: Add pending action */
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
      ],
    },
    {
      path: "kanban",
      handle: {
        crumb: "Assistência técnica",
        action: "get.maintenance",
      } as Handle,
      children: [serviceDetailRouter],
    },
    {
      path: "lista",
      handle: {
        crumb: "Assistência técnica",
        action: "get.maintenance",
      } as Handle,
      children: [serviceDetailRouter],
    },
  ],
};
