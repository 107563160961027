import { useParams } from "react-router-dom";

import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useCompany, useProfile } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";
import { cepMask, cnpjMask, phoneMask } from "@/shared/utils/masks";

import { CompanyFormWizard } from "../../create/components/company-create-form-wizard";
import { CompanyEditFormSchema, CompanyEditSchema } from "../schema";

export function CompanyEdit() {
  const { companyId } = useParams();
  const profileQuery = useProfile();
  const userCompanyId = profileQuery.data?.construtoraId?.toString();
  const companyQuery = useCompany(companyId ?? userCompanyId);

  const getDefaultValues = (data: typeof companyQuery.data) => {
    if (!data) {
      return undefined;
    }
    const {
      company: { cep, foto, telefone, whatsapp, ...values },
    } = data;
    return {
      defaultValues: {
        ...values,
        construtoraId: companyQuery.data?.company.id,
        cep: cep ? cepMask(cep) : undefined,
        cnpj: cnpjMask(values.cnpj),
        telefone: telefone ? phoneMask(telefone) : undefined,
        whatsapp: whatsapp ? phoneMask(whatsapp) : undefined,
        foto: foto
          ? [
              {
                fileName: foto,
                key: foto,
              },
            ]
          : undefined,
      },
    };
  };
  return (
    <AnimatedContainer className="relative m-0 flex h-full flex-col overflow-y-auto">
      <div className="mb-20 flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Editar construtora</PageTitle>
        <QueryHandler
          {...companyQuery}
          renderLoading={() => <Spinner />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? "Construtora não encontrada..."
                  : undefined
              }
            />
          )}
        >
          {(data) => (
            <CompanyFormWizard
              formSchema={CompanyEditFormSchema}
              submitSchema={CompanyEditSchema}
              useFormProps={getDefaultValues(data)}
              isEditing
            />
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
