import {
  AnimatedContainer,
  InfiniteQueryHandler,
  Separator,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteSignature } from "@/shared/hooks/api";

import { SignatureCardContent } from "../components";

export function SignatureList() {
  const { serviceId } = useRequiredParams();
  const [{ flatData, isFetching, error }, , { ref }] = useInfiniteSignature({
    manutencaoId: +serviceId,
  });

  return (
    <AnimatedContainer className="mt-0 overflow-auto rounded-lg bg-white-0 px-6 pt-4">
      <div className="flex gap-4">
        <span className="text-base font-bold text-black-2">Assinaturas</span>
      </div>

      <Separator />
      <div className="flex flex-col gap-4 overflow-auto pb-6">
        <InfiniteQueryHandler
          isFetching={isFetching}
          error={error}
          isEmpty={!flatData?.length}
        >
          {flatData?.map((signature) => (
            <div
              key={signature.id}
              ref={signature.id === flatData.length - 1 ? ref : null}
            >
              <SignatureCardContent signature={signature} />
            </div>
          ))}
        </InfiniteQueryHandler>
      </div>
    </AnimatedContainer>
  );
}
