import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import {
  Breadcrumbs,
  PageTitle,
  SubtitleHandler,
  Tabs,
} from "@/shared/components";
import {
  useAuthorizedTabs,
  useRequiredParams,
  useTabBaseUrl,
} from "@/shared/hooks";
import { useSubsystem, useSystem } from "@/shared/hooks/api";

export function SubsystemLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("subsystem");
  const { systemId, subsystemId } = useRequiredParams();

  const systemQuery = useSystem(systemId);
  const subsystemQuery = useSubsystem(subsystemId);

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do subsistema",
      action: "get.subsystem",
    },
    {
      id: `${baseUrl}garantia/`,
      href: `${baseUrl}garantia/`,
      label: "Garantias",
      action: "list.warranty",
    },
    {
      id: `${baseUrl}preventivo/`,
      href: `${baseUrl}preventivo/`,
      label: "Preventivos",
      action: "list.preventive",
    },
    {
      id: `${baseUrl}produto/`,
      href: `${baseUrl}produto/`,
      label: "Produtos",
      action: "list.product",
    },
    {
      id: `${baseUrl}faq/`,
      href: `${baseUrl}faq/`,
      label: "Faqs",
      action: "list.faq",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <div className="flex flex-col">
          <PageTitle primary>Detalhes do Subsistema</PageTitle>
          <SubtitleHandler label="Sistema" query={systemQuery} />
          <SubtitleHandler label="Subsistema" query={subsystemQuery} />
        </div>
      </div>
      <Tabs
        aria-label="Modulos do subsistema"
        tabs={tabs}
        selectedKey={pathname}
      >
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
