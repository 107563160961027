import { Link } from "react-router-dom";

import { TextTooltip } from "@/shared/components";
import { useCurrentPath } from "@/shared/hooks";
import { cn } from "@/shared/utils";

interface DrawerElementProps extends React.ComponentProps<typeof Link> {
  icon: (selected: boolean) => React.ReactNode;
  tooltipText: string;
}

export default function DrawerElement({
  children,
  icon,
  tooltipText,
  className,
  ...linkProps
}: DrawerElementProps) {
  const { isCurrentPath } = useCurrentPath(`${linkProps.to.toString()}/*`);

  return (
    <TextTooltip text={tooltipText} className="inline lg:hidden">
      <Link
        className={cn(
          "flex items-center gap-2 rounded-lg p-3 text-sm transition-all",
          {
            "translate-x-1 transform bg-primary/10 font-bold text-primary":
              isCurrentPath,
            "font-medium text-gray-6 hover:bg-black-0/5": !isCurrentPath,
          },
          className,
        )}
        {...linkProps}
      >
        {icon(isCurrentPath)}
        <span className="hidden lg:inline">{children}</span>
      </Link>
    </TextTooltip>
  );
}
