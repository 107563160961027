import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router-dom";

import {
  AnimatedContainer,
  Breadcrumbs,
  PageTitle,
  Tabs,
} from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function BlockLayout() {
  const { pathname } = useLocation();

  const baseUrl = useTabBaseUrl("block");
  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados do bloco",
      action: "get.block",
    },
    {
      id: `${baseUrl}unidade/`,
      href: `${baseUrl}unidade/`,
      label: "Unidades",
      action: "list.unit",
    },
  ]);

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-6 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Detalhes do Bloco</PageTitle>
      </div>
      <Tabs aria-label="Modulos do bloco" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </AnimatedContainer>
  );
}
