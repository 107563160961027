import { Handle, RouteObject } from "react-router-dom";

import { UnitsList } from "../companies/containers";
import { unitDetailRouter } from "../companies/modules/developments/modules/detail/modules/block/modules/detail/modules/unit/router";
import { createClientAction } from "./actions";
import { CreateClient, ListClients } from "./containers";
import { ClientData } from "./modules/detail/containers";
import { ClientLayout } from "./modules/detail/layout";
import { clientEditAction } from "./modules/edit/actions";
import { ClientEdit } from "./modules/edit/containers";

export const clientsRouter: RouteObject = {
  path: "cliente",
  handle: {
    crumb: "Lista de clientes",
    action: "list.client",
  } as Handle,
  children: [
    {
      index: true,
      element: <ListClients />,
    },
    {
      path: "detalhes/:clientId",
      handle: {
        crumb: "Detalhes do cliente",
        tabBaseUrl: "client",
        action: "get.client",
      } as Handle,
      children: [
        {
          element: <ClientLayout />,
          children: [
            {
              index: true,
              element: <ClientData />,
            },
            {
              path: "unidade",
              handle: {
                crumb: "Lista de unidades",
                action: "list.unit",
              } as Handle,
              element: <UnitsList />,
            },
          ],
        },
        {
          path: "editar",
          handle: {
            crumb: "Editar cliente",
            action: "update.client",
          } as Handle,
          action: clientEditAction,
          element: <ClientEdit />,
        },
        {
          path: "unidade",
          handle: { crumb: "Lista de unidades" } as Handle,
          children: [unitDetailRouter],
        },
      ],
    },
    {
      path: "cadastrar",
      handle: {
        crumb: "Cadastrar cliente",
        action: "create.client",
      } as Handle,
      action: createClientAction,
      element: <CreateClient />,
    },
  ],
};
