import { ActionFunctionArgs, redirect } from "react-router-dom";

import { toastQueue } from "@/shared/components/toast";
import { MemorialCreate, apiProvider } from "@/shared/services";
import {
  HandleActionReturnType,
  handleAction,
} from "@/shared/utils/handle-action";

export async function memorialEditAction({
  request,
  params,
}: ActionFunctionArgs): Promise<HandleActionReturnType> {
  return handleAction<MemorialCreate & { redirectTo: string }>(
    request,
    async ({ redirectTo, ...requestBody }) => {
      const memorialId = +params.memorialId!;

      await apiProvider.services.MemoriaisService.updateObjMemorialIdPut({
        requestBody,
        id: memorialId,
      });

      toastQueue.add(
        { type: "success", message: "Ambiente atualizado com sucesso" },
        {
          timeout: 5000,
        },
      );
      return redirect(redirectTo);
    },
  );
}
