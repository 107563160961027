import { tv } from "tailwind-variants";

export const button = tv({
  base: "flex items-center justify-center rounded-md p-2 font-bold",
  variants: {
    color: {
      primary: "text-white-0",
      disabled: "text-white-0",
      info: "text-white-0",
      error: "text-white-0",
    },
    styleType: {
      default: "bg-transparent",
      contained: "bg-primary",
      outlined: "border border-primary",
      secondary: "bg-primary-light-1",
      menu: "bg-primary",
    },
  },
  defaultVariants: {
    color: "primary",
    styleType: "contained",
  },
  compoundVariants: [
    {
      color: "primary",
      styleType: "default",
      class: "text-primary",
    },
    {
      color: "disabled",
      styleType: "default",
      class: "text-gray-4",
    },
    {
      color: "primary",
      styleType: "secondary",
      class: "text-primary",
    },
    {
      color: "info",
      styleType: "secondary",
      class: "bg-info-20 text-info",
    },
    {
      color: "error",
      styleType: "secondary",
      class: "bg-error-20 text-error",
    },
    {
      color: "error",
      styleType: "contained",
      class: "bg-error",
    },
    {
      styleType: "menu",
      class: "text-sm font-medium",
    },
  ],
});
