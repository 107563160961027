import { TabPanel } from "react-aria-components";
import { Outlet, useLocation, useParams } from "react-router-dom";

import {
  Breadcrumbs,
  PageTitle,
  SubtitleHandler,
  Tabs,
} from "@/shared/components";
import {
  useAuthorizedTabs,
  useRequiredParams,
  useTabBaseUrl,
} from "@/shared/hooks";
import {
  useProduct,
  useSubsystem,
  useSystem,
  useWarranty,
} from "@/shared/hooks/api";

export function WarrantyDetailLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("warranty");
  const { warrantyId } = useRequiredParams();
  const { systemId, subsystemId, productId } = useParams();

  const systemQuery = useSystem(systemId);
  const subsystemQuery = useSubsystem(subsystemId);
  const productQuery = useProduct(productId);
  const warrantyQuery = useWarranty(warrantyId);

  const tabs = useAuthorizedTabs([
    {
      id: baseUrl,
      href: baseUrl,
      label: "Dados da garantia",
      action: "get.warranty",
    },
    {
      id: `${baseUrl}falhas/`,
      href: `${baseUrl}falhas/`,
      label: "Falhas",
      action: "get.failure",
    },
  ]);

  return (
    <div className="flex h-full flex-col space-y-6 overflow-y-auto p-8 animate-in fade-in slide-in-from-left-2">
      <div className="space-y-4">
        <Breadcrumbs />
        <div className="flex flex-col">
          <PageTitle primary>Detalhes da garantia</PageTitle>
          <div className="flex flex-col gap-1">
            {systemId && (
              <SubtitleHandler label="Sistema" query={systemQuery} />
            )}
            {subsystemId && (
              <SubtitleHandler label="Subsistema" query={subsystemQuery} />
            )}
            {productId && (
              <SubtitleHandler label="Produto" query={productQuery} />
            )}
            <SubtitleHandler label="Garantia" query={warrantyQuery} />
          </div>
        </div>
      </div>
      <Tabs aria-label="Modulos da garantia" tabs={tabs} selectedKey={pathname}>
        <TabPanel id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
}
