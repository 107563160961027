import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { UseFormProps, useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  FormNbrPickerInput,
  FormNumberInput,
  FormRichTextInput,
  FormSelectClientTypeInput,
  FormSelectTimeUnitInput,
  FormTextInput,
  Modal,
} from "@/shared/components";

import { PreventiveCommon, PreventiveCommonSchema } from "../schemas";

interface AddPreventiveModalProps extends Omit<ModalOverlayProps, "children"> {
  onAdd: (value: PreventiveCommon) => void;
  useFormProps?: UseFormProps<PreventiveCommon>;
}

export function AddPreventiveModal({
  onAdd,
  useFormProps,
  ...props
}: AddPreventiveModalProps) {
  const form = useForm<PreventiveCommon>({
    resolver: zodResolver(PreventiveCommonSchema),
    ...useFormProps,
  });
  const { formState, handleSubmit, control, reset } = form;

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Adicionar manutenção preventiva"
        renderButtons={({ close }) => (
          <>
            <Button className="px-7 py-3" styleType="default" onPress={close}>
              Cancelar
            </Button>
            <Button className="px-7 py-3" onPress={() => handleSubmit(onAdd)()}>
              Adicionar
            </Button>
          </>
        )}
        data-cy="preventive-modal"
      >
        <form className="grid grid-cols-4 gap-x-4 gap-y-6 px-1 pb-4">
          <FormTextInput
            control={control}
            className="col-span-4"
            label="Título"
            name="nome"
          />
          <FormNumberInput
            control={control}
            className="col-span-2"
            label="Frequência"
            name="frequencia"
            minValue={0}
          />
          <FormSelectTimeUnitInput
            control={control}
            className="col-span-2"
            name="unidadeTempoId"
          />
          <FormNbrPickerInput control={control} name="nbrId" />
          <FormSelectClientTypeInput
            className="col-span-3"
            control={control}
            name="tipoClienteId"
          />
          <FormRichTextInput
            control={control}
            className="col-span-4"
            label="Descrição"
            name="descricao"
          />
        </form>
      </Dialog>
    </Modal>
  );
}
