import { PreventiveCommonSchema } from "@/shared/modules/system/modules/detail/modules/subsystem/modules/create/schemas";
import { WarrantyCommonSchema } from "@/shared/modules/warranty/modules/create/schemas";
import { z } from "@/shared/utils";
import { generateMultiStepSchema } from "@/shared/utils/generate-multi-step-schema";
import { generateStepSchema } from "@/shared/utils/generate-step-schema";

export const ProductGeneralDataSchema = z.object({
  fabricante: z.string().trim().min(1, "Obrigatório").nullish(),
  nome: z
    .string()
    .trim()
    .min(1, "Obrigatório")
    .max(300, "Máximo de 300 caracteres"),
  memorialId: z.number().int().nullish(),
  modelo: z.string().trim().min(1, "Obrigatório").nullish(),
  linkManual: z
    .string()
    .startsWith("https://", "Precisa começar com https://")
    .url()
    .trim()
    .nullish(),
  descricao: z.string().trim().nullish(),
  fornecedorFk: z.number().int().nullish(),
  projetistaFk: z.number().int().nullish(),
  notaFiscal: z
    .array(
      z.object({
        fileName: z.string(),
        key: z.string(),
      }),
    )
    .max(1)
    .default([]),
});

export const ProductTechnicalDataSchema = z.object({
  cuidados: z.string().trim().nullish(),
  perdas: z.string().trim().nullish(),
  garantias: z.array(WarrantyCommonSchema).default([]),
  manutencoes: z.array(PreventiveCommonSchema).default([]),
});

export const ProductCreateSchema = z.object({
  ...ProductGeneralDataSchema.shape,
  ...ProductTechnicalDataSchema.shape,
  subsistemaId: z.number().int().nullish(),
});

export const ProductGeneralDataStepSchema = generateStepSchema(
  ProductGeneralDataSchema,
  0,
);
const ProductTechnicalDataStepSchema = generateStepSchema(
  ProductTechnicalDataSchema,
  1,
);

export const ProductCreateFormSchema = generateMultiStepSchema([
  ProductGeneralDataStepSchema,
  ProductTechnicalDataStepSchema,
]).and(
  z.object({
    subsistemaId: z.number().int().nullish(),
  }),
);

export type ProductGeneralData = z.infer<typeof ProductGeneralDataSchema>;
export type ProductTechnicalData = z.infer<typeof ProductTechnicalDataSchema>;
export type ProductCreate = z.infer<typeof ProductCreateSchema>;
