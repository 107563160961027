import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  Card,
  FieldSetTitle,
  FormComboBoxDesignerInput,
  FormComboBoxSupplierInput,
  FormRichTextInput,
  FormSelectClientTypeInput,
  FormTextInput,
} from "@/shared/components";

import { SystemCreate } from "../schemas";

export function SystemDataForm() {
  const { developmentId, manualId } = useParams();

  const { control } = useFormContext<SystemCreate>();

  return (
    <Card className="mb-4 p-6">
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6">
        <FormTextInput control={control} label="Nome" name="nome" />
        <FormSelectClientTypeInput
          control={control}
          name="tipoId"
          isReadOnly={!!manualId}
        />
        <FormComboBoxSupplierInput
          name="fornecedorFk"
          control={control}
          filters={
            developmentId
              ? { empreendimentoId: +developmentId, status: true }
              : { status: true }
          }
        />
        <FormComboBoxDesignerInput
          name="projetistaFk"
          control={control}
          filters={
            developmentId
              ? { empreendimentoId: +developmentId, status: true }
              : { status: true }
          }
        />
        <FormRichTextInput
          control={control}
          className="col-span-2"
          label="Descrição"
          name="descricao"
        />
        <FormRichTextInput
          control={control}
          className="col-span-2"
          label="Cuidados de uso"
          name="cuidados"
        />
        <FormRichTextInput
          control={control}
          className="col-span-2"
          label="Perdas de garantia"
          name="perdas"
        />
      </div>
    </Card>
  );
}
