import { MedalStar } from "iconsax-react";
import React from "react";

import { Separator } from "@/shared/components";
import { Avaliacao } from "@/shared/services";
import { cn, sanitize } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

interface RatingCardProps {
  rating: Avaliacao;
  classname?: string;
}

export const RatingCard = React.forwardRef<HTMLDivElement, RatingCardProps>(
  ({ rating, classname }, ref) => {
    return (
      <div
        className={cn("rounded-lg border border-white-3 px-6 py-4", classname)}
        ref={ref}
        key={rating.id}
      >
        <div className="flex items-center justify-between">
          <span className="text-base font-bold text-black-2">
            {rating.usuario?.nome}
          </span>
          <div className="flex items-center gap-2 text-base font-bold text-black-2">
            <span className="font-light">Avaliação</span>
            <MedalStar className="text-danger" variant="Bold" />
            <span className="text-2xl font-semibold">
              {rating.notaServico ?? 0}
            </span>
          </div>
        </div>
        <Separator className="mt-2" />

        <p className="text-sm text-gray-8">
          {sanitize(nullishStringMask(rating.texto))}
        </p>
      </div>
    );
  },
);
