import { Outlet, useLocation } from "react-router-dom";

import { AnimatedContainer, TabPanel, Tabs } from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

import { CallDetailData } from "../containers";

export function DetailServiceLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("service");

  const tabs = useAuthorizedTabs([
    {
      id: `${baseUrl}geral/`,
      href: `${baseUrl}geral/`,
      label: "Geral",
    },
    {
      id: `${baseUrl}custos/`,
      href: `${baseUrl}custos/`,
      label: "Custos",
    },
    {
      id: `${baseUrl}arquivos-e-midias/`,
      href: `${baseUrl}arquivos-e-midias/`,
      label: "Arquivos e mídias",
    },
    {
      id: `${baseUrl}diagnostico/`,
      href: `${baseUrl}diagnostico/`,
      label: "Diagnóstico",
    },
    {
      id: `${baseUrl}comentarios/`,
      href: `${baseUrl}comentarios/`,
      label: "Comentários",
    },
    {
      id: `${baseUrl}agendamentos/`,
      href: `${baseUrl}agendamentos/`,
      label: "Agendamentos",
    },
    {
      id: `${baseUrl}historico/`,
      href: `${baseUrl}historico/`,
      label: "Histórico",
    },
    {
      id: `${baseUrl}avaliacoes/`,
      href: `${baseUrl}avaliacoes/`,
      label: "Avaliações",
    },
    {
      id: `${baseUrl}assinaturas/`,
      href: `${baseUrl}assinaturas/`,
      label: "Assinaturas",
    },
  ]);
  return (
    <CallDetailData>
      <AnimatedContainer className="mt-0">
        <Tabs
          aria-label="Módulos da construtora"
          tabs={tabs}
          selectedKey={pathname}
          className="rounded-none rounded-b-lg px-6 shadow-none"
        >
          <TabPanel id={pathname}>
            <Outlet />
          </TabPanel>
        </Tabs>
      </AnimatedContainer>
    </CallDetailData>
  );
}
